import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import { path } from 'ramda';

import { axiosInstance } from 'src/utils/fetcher';
import { decamelizeKeys, camelizeKeys } from 'src/utils/keysConverter';

const baseQueryFn: BaseQueryFn = async (args, api, extraOptions) => {
  try {
    const argsConfig =
      typeof args === 'string'
        ? { url: args }
        : {
            ...args,
            params: decamelizeKeys(args.params),
            data: decamelizeKeys(args.body),
          };

    const results = await axiosInstance({
      ...argsConfig,
      signal: api.signal,
      ...extraOptions,
    });

    return {
      data: results.data,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    const errorsPath = ['response', 'data'];
    return {
      error: {
        status: err.response?.status,
        data: camelizeKeys(path(errorsPath, err)),
      },
    };
  }
};

export const emptyApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryFn,
  tagTypes: ['EngagementPOs', 'OrganizationPOs', 'EngagementTerms', 'OpportunitiesListTalent', 'TalentProfileVideo'],
  endpoints: () => ({}),
});
