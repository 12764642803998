import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';
import { makeStyles } from 'src/utils/makeStyles';

const styles: SxStyles = makeStyles({
  wrapper: {
    position: 'relative',
    margin: '14px 0',
    width: '100%',
    '& .public-DraftEditorPlaceholder-root': {
      position: 'absolute',
      padding: '10px 12px',
      top: '0',
      left: '0',
    },
    '.DraftEditor-root': {
      position: 'relative',
      color: COLORS.black100,
      border: `1px solid ${COLORS.black20}`,
      boxSizing: 'border-box',
      borderRadius: '0 0 4px 4px',
      padding: '10px 12px',
    },
    '& h1,h2,h3,h4,h5,h6': {
      fontSize: '14px',
      fontWeight: '600',
    },
    '& *': {
      fontSize: 'inherit',
    },
    '& > *:not(label)': {
      fontSize: '13px',
    },
  },
  disableVerticalGutters: {
    marginY: 0,
  },
  hasFocus: {
    '& .DraftEditor-root': {
      boxShadow: `0 0 0 2px ${COLORS.black60}`,
    },
  },
  usePredefinedMinHeight: {
    '& .public-DraftEditor-content': {
      minHeight: '5em',
    },
  },
  hasError: {
    '& > .DraftEditor-root': {
      boxShadow: `0 0 0 1px ${COLORS.red100}`,
    },
  },
  readyOnlyStyles: {
    margin: '0',
    '& > div.DraftEditor-root': {
      padding: '0',
      border: 'none',
    },
    '& [data-contents="true"] > *:first-of-type': {
      marginTop: '0',
    },
  },
  truncateReadOnlyStyles: {
    margin: '0',
    width: 'fit-content',
    '.typographyNoWrap > div.DraftEditor-root': {
      padding: '0',
      border: 'none',
    },
  },
  counter: {
    display: 'block',
    marginTop: '3px',
    color: COLORS.black40,
    fontSize: '8px',
    textAlign: 'right',
  },
  absolute: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
});

export default styles;
